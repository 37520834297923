import { PaginationQuery } from "@/types/RemoteTypes";
import {
  DataTablePaginationOptions,
  DataFooterProperties,
} from "@/types/VuetifyTypes";

const ITEMS_PER_PAGE = 10;

interface ListSearchSettings {
  search: string;
  totalElements: number;
  loading: boolean;
  dataTablePaginationOptions: DataTablePaginationOptions;
  dataFooterProperties: DataFooterProperties;
  itemsPerPage: number;
  createPaginationQuery(): PaginationQuery;
}

abstract class AbstractSearchableListSettingsImpl
  implements ListSearchSettings
{
  private _search: string;

  private _totalElements: number;
  private _loading: boolean;
  private _dataTablePaginationOptions: DataTablePaginationOptions;
  private _dataFooterProperties: DataFooterProperties;
  private _itemsPerPage: number;

  protected constructor(
    dataTablePaginationOptions: DataTablePaginationOptions
  ) {
    this._search = "";

    this._totalElements = 0;
    this._loading = true;
    this._dataTablePaginationOptions = dataTablePaginationOptions;
    this._dataFooterProperties = {
      itemsPerPageOptions: [5, 10, 25, 50],
    };
    this._itemsPerPage = ITEMS_PER_PAGE;
  }

  get search(): string {
    return this._search;
  }

  set search(value: string) {
    if (value !== this._search) {
      this._dataTablePaginationOptions.page = 1;
    }

    this._search = value;
  }

  get totalElements(): number {
    return this._totalElements;
  }

  set totalElements(value: number) {
    this._totalElements = value;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get dataTablePaginationOptions(): DataTablePaginationOptions {
    return this._dataTablePaginationOptions;
  }

  set dataTablePaginationOptions(value: DataTablePaginationOptions) {
    this._dataTablePaginationOptions = value;
  }

  get dataFooterProperties(): DataFooterProperties {
    return this._dataFooterProperties;
  }

  set dataFooterProperties(value: DataFooterProperties) {
    this._dataFooterProperties = value;
  }

  get itemsPerPage(): number {
    return this._itemsPerPage;
  }

  set itemsPerPage(value: number) {
    this._itemsPerPage = value;
  }

  createPaginationQuery(): PaginationQuery {
    return new PaginationQuery(
      this.dataTablePaginationOptions.page,
      this.dataTablePaginationOptions.itemsPerPage,
      this.dataTablePaginationOptions.sortBy,
      this.dataTablePaginationOptions.sortDesc
    );
  }
}

class OCRParticipantListSettings extends AbstractSearchableListSettingsImpl {
  constructor() {
    super({
      sortBy: ["name"],
      sortDesc: [false],
    });
  }
}

export { OCRParticipantListSettings };
