

































































































































import { mdiMicrosoftExcel, mdiFileUploadOutline } from "@mdi/js";

import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Watch } from "vue-property-decorator";

import ComponentContainer from "@/components/util/ComponentContainer.vue";
import { SelectItem, TableHeader } from "@/types/VuetifyTypes";

import {
  OCRParticipant,
  OCRParticipantStatus,
  OCRParticipantSearch,
} from "@/types/OCRTypes";
import { OCRParticipantListSettings } from "@/types/HelperTypes";
import { OCRServiceProvider } from "@/services/remote/OcrService";

import { ticketLinkFor } from "@/utils/UrlUtils";
import { NotificationService } from "@/services/NotificationService";

import debounce from "lodash/debounce";
import { DEBOUNCE_WAIT } from "@/utils/Constants";
import { UiState } from "@/UiState";

import {
  createLocalDownload,
  coerceValidFileName,
} from "@/utils/DownloadUtils";

import { convertToDateTime } from "@/utils/FormatUtils";

@Component({
  components: {
    ComponentContainer,
  },
})
export default class ParticipantsComponent extends Vue {
  listSettings: OCRParticipantListSettings = new OCRParticipantListSettings();

  @Prop()
  search!: OCRParticipantSearch;

  initialParticipantsLoading = false;

  items: OCRParticipant[] = [];

  searchRunItems: Array<string> = [];
  searchStatusItems: Array<SelectItem> = [];

  fileToUpload: File | null = null;

  get downloadIcon(): string {
    return mdiMicrosoftExcel;
  }

  get uploadIcon(): string {
    return mdiFileUploadOutline;
  }

  createdSupplier(created: string): string {
    return convertToDateTime(created);
  }

  private createSearchStatusSelectItems(): void {
    this.searchStatusItems.push({
      text: this.$t(
        "ocr.participants.table.filter.participationStatus.NONE"
      ) as string,
      value: OCRParticipantStatus.NONE,
    });

    this.searchStatusItems.push({
      text: this.$t(
        "ocr.participants.table.filter.participationStatus.PAID_NOT_CONFIRMED"
      ) as string,
      value: OCRParticipantStatus.PAID_NOT_CONFIRMED,
    });

    this.searchStatusItems.push({
      text: this.$t(
        "ocr.participants.table.filter.participationStatus.PAID_AND_CONFIRMED"
      ) as string,
      value: OCRParticipantStatus.PAID_AND_CONFIRMED,
    });
  }

  get headers(): TableHeader[] {
    const result: TableHeader[] = [];

    result.push(this.createHeaderColumn("created"));
    result.push(this.createHeaderColumn("name"));
    result.push(this.createHeaderColumn("foreName"));
    result.push(this.createHeaderColumn("teamCaptain"));
    result.push(this.createHeaderColumn("teamName"));
    result.push(this.createHeaderColumn("runName"));
    result.push(this.createHeaderColumn("email"));
    result.push(this.createHeaderColumn("shirtSize"));
    result.push(this.createHeaderColumn("gender"));
    result.push(this.createHeaderColumn("dayOfBirth"));
    result.push(this.createHeaderColumn("runStart"));
    result.push(this.createHeaderColumn("liabilityConsent"));
    result.push(this.createHeaderColumn("termsAndConditionsConsent"));
    result.push(this.createHeaderColumn("generalTermsAndConditionsConsent"));
    result.push(this.createHeaderColumn("startNr"));
    result.push(this.createHeaderColumn("ticketNumber"));

    return result;
  }

  private createHeaderColumn(field: string): TableHeader {
    return {
      text: this.$t("ocr.participants.table." + field) as string,
      align: "center",
      sortable: false,
      filterable: false,
      value: field,
    };
  }

  private loadParticipantsFromApi(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const ocrService = OCRServiceProvider.getInstance().getService();
      const eventId = UiState.getInstance().ocrEvent.id;

      this.listSettings.loading = true;

      resolve(
        ocrService
          .findParticipants(
            eventId,
            this.search,
            this.listSettings.createPaginationQuery()
          )
          .then((page) => {
            this.items = page.elements;
            this.listSettings.totalElements = page.totalElements;
          })
          .finally(() => {
            this.listSettings.loading = false;
          })
      );
    });
  }

  private loadParticipants(
    restartPagination: boolean,
    scrollDown: boolean
  ): void {
    const listSettings = this.listSettings;

    NotificationService.getInstance().indicateLoading(
      this.loadParticipantsFromApi().then(() => {
        if (restartPagination) {
          listSettings.dataTablePaginationOptions.page = 1;
        }

        if (scrollDown) {
          this.$vuetify.goTo(document.body.scrollHeight);
        }
      }),
      this.$t("ocr.participants.loadingMessage") as string
    );
  }

  private loadParticipantsDebounced(
    restartPagination: boolean,
    scrollDown: boolean
  ) {
    debounce(() => {
      this.loadParticipants(restartPagination, scrollDown);
    }, DEBOUNCE_WAIT)();
  }

  private uploadParticipantsToApi(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.fileToUpload === null) {
        resolve();
        return;
      }

      const ocrService = OCRServiceProvider.getInstance().getService();
      const eventId = UiState.getInstance().ocrEvent.id;

      this.listSettings.loading = true;

      resolve(
        ocrService
          .uploadParticipants(eventId, this.fileToUpload!)
          .then(() => {
            this.fileToUpload = null;
          })
          .finally(() => {
            this.listSettings.loading = false;
          })
      );
    });
  }

  private uploadParticipants(): void {
    NotificationService.getInstance().indicateLoading(
      this.uploadParticipantsToApi().then(() =>
        this.loadParticipantsDebounced(true, true)
      ),
      this.$t("ocr.participants.uploadingMessage") as string
    );
  }

  private uploadParticipantsDebounced() {
    debounce(() => {
      this.uploadParticipants();
    }, DEBOUNCE_WAIT)();
  }

  private downloadParticipantsFromApi(): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      const ocrService = OCRServiceProvider.getInstance().getService();
      const eventId = UiState.getInstance().ocrEvent.id;

      this.listSettings.loading = true;

      resolve(
        ocrService.downloadParticipants(eventId, this.search).finally(() => {
          this.listSettings.loading = false;
        })
      );
    });
  }

  private downloadParticipants(): void {
    const eventName = UiState.getInstance().ocrEvent.name;

    NotificationService.getInstance().indicateLoading(
      this.downloadParticipantsFromApi().then((blob) =>
        createLocalDownload(
          blob,
          coerceValidFileName(
            eventName + this.$t("ocr.participants.downloadFileSuffix")
          )
        )
      ),
      this.$t("ocr.participants.loadingMessage") as string
    );
  }

  private downloadParticipantsDebounced() {
    debounce(() => {
      this.downloadParticipants();
    }, DEBOUNCE_WAIT)();
  }

  @Watch("listSettings.dataTablePaginationOptions")
  onOptionsChangedHandler() {
    if (this.initialParticipantsLoading) {
      this.initialParticipantsLoading = false;
      return;
    }

    this.loadParticipantsDebounced(false, true);
  }

  @Watch("search.lastName")
  onSearchLastNameChanged() {
    this.loadParticipantsDebounced(true, true);
  }

  @Watch("search.firstName")
  onSearchFirstNameChanged() {
    this.loadParticipantsDebounced(true, true);
  }

  @Watch("search.teamname")
  onSearchTeamNameChanged() {
    this.loadParticipantsDebounced(true, true);
  }

  @Watch("search.email")
  onSearchEmailChanged() {
    this.loadParticipantsDebounced(true, true);
  }

  @Watch("search.status")
  onSearchStatusChanged() {
    this.loadParticipantsDebounced(true, true);
  }

  @Watch("search.ticketCode")
  onSearchTicketCodeChanged() {
    this.loadParticipantsDebounced(true, true);
  }

  @Watch("search.eventItem")
  onSearchRunNameChanged() {
    this.loadParticipantsDebounced(true, true);
  }

  onParticipantClicked(participant: OCRParticipant): void {
    this.navigateToTicket(participant);
  }

  onUploadClickedHandler(): void {
    const upload = document.getElementById("fileUpload");
    upload?.click();
  }

  onFileToUploadChangedHandler() {
    const upload: any = document.getElementById("fileUpload");
    if (upload === null) return;
    const files: ReadonlyArray<File> = upload.files;

    if (files.length !== 1) return;

    this.fileToUpload = files[0];

    this.uploadParticipantsDebounced();
  }

  onDownloadClickedHandler(): void {
    this.downloadParticipantsDebounced();
  }

  private navigateToTicket(participant: OCRParticipant): void {
    const curPath = this.$route.path;
    const destPath = ticketLinkFor(participant.id);

    if (curPath === destPath) return;

    this.$router.push(destPath).catch((err) => {
      NotificationService.getInstance().notifyError(err.message);
    });
  }

  mounted(): void {
    this.listSettings = new OCRParticipantListSettings();

    this.searchRunItems = [""].concat(UiState.getInstance().ocrEventRuns);
    this.createSearchStatusSelectItems();

    this.initialParticipantsLoading = true;
    this.loadParticipantsDebounced(true, false);
  }
}
