









import Vue from "vue";
import Component from "vue-class-component";

import ChartComponent from "@/components/util/ChartComponent.vue";

import { createLineChart } from "@/utils/ChartUtils";
import { ApexOptions } from "apexcharts";
import { ApexAxisChartSeries } from "@/types/CustomUiDataTypes";

import {
  OCRChartType,
  OCREventDetails,
  asChartValueType,
} from "@/types/OCRTypes";
import { OCRServiceProvider } from "@/services/remote/OcrService";
import { NotificationService } from "@/services/NotificationService";

import { Prop } from "vue-property-decorator";
import debounce from "lodash/debounce";
import { DEBOUNCE_WAIT } from "@/utils/Constants";

@Component({
  components: {
    ChartComponent,
  },
})
export default class TimeSeriesComponent extends Vue {
  @Prop()
  eventDetails!: OCREventDetails;

  chartOptions: ApexOptions = {};
  chartSeries: ApexAxisChartSeries[] = [];

  title: string = this.$t("ocr.chartTypes.Registrations") as string;
  ocrChartType: OCRChartType = OCRChartType.Registrations;

  private rotateChartType(): void {
    const enumSize = Object.keys(OCRChartType).length / 2;
    const enumKeys = Object.keys(OCRChartType);

    this.ocrChartType = (this.ocrChartType + 1) % enumSize;
    this.title = this.$t(
      "ocr.chartTypes." + enumKeys[this.ocrChartType + enumSize]
    ) as string;
  }

  loadDataFromApi(): Promise<void> {
    const ocrService = OCRServiceProvider.getInstance().getService();

    return ocrService
      .getEventChartData(this.eventDetails.id, this.ocrChartType)
      .then((chartData) => {
        [this.chartOptions, this.chartSeries] = createLineChart(
          chartData.categories,
          chartData.values,
          asChartValueType(this.ocrChartType)
        );
      });
  }

  loadData(): void {
    const notificationService = NotificationService.getInstance();
    notificationService.indicateLoading(
      this.loadDataFromApi(),
      this.$t("messages.loadingData") as string
    );
  }

  loadDataDebounced(): void {
    debounce(() => {
      this.loadData();
    }, DEBOUNCE_WAIT)();
  }

  onTitleClickedHandler(): void {
    this.rotateChartType();

    this.loadDataDebounced();
  }

  mounted(): void {
    this.loadData();
  }
}
