interface Page<TType> {
  elements: Array<TType>;

  totalPages: number;
  totalElements: number;
  number: number;
  size: number;

  first: boolean;
  last: boolean;
  next: boolean;
  previous: boolean;
}

class PaginationQuery {
  private _page: number;
  private _size: number;
  private _sortBy: string[];
  private _sortDesc: boolean[];

  constructor(
    page = 1,
    size = 10,
    sortBy: string[] = [],
    sortDesc: boolean[] = []
  ) {
    this._page = page;
    this._size = size;
    this._sortBy = sortBy;
    this._sortDesc = sortDesc;
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

  get size(): number {
    return this._size;
  }

  set size(value: number) {
    this._size = value;
  }

  get sortBy(): string[] {
    return this._sortBy;
  }

  set sortBy(value: string[]) {
    this._sortBy = value;
  }

  get sortDesc(): boolean[] {
    return this._sortDesc;
  }

  set sortDesc(value: boolean[]) {
    this._sortDesc = value;
  }

  asQueryString(sortOnly: boolean): string {
    let result = "?";

    const sort = this.getSort();

    if (sortOnly) {
      if (sort.length > 0) {
        return result + sort;
      }
      return "";
    }

    result += `page=${this.page - 1}`;

    result += "&";
    result += `size=${this.size}`;

    if (sort.length > 0) result += "&" + sort;

    return result;
  }

  private getSort(): string {
    let result = "";

    if (this.sortBy.length > 0 && this.sortBy.length === this.sortDesc.length) {
      const firstField = this.sortBy[0];
      const firstIsSortDescending = this.sortDesc[0];

      result = `sort=${encodeURIComponent(firstField)}.${
        firstIsSortDescending ? "desc" : "asc"
      }`;

      for (let i = 1; i < this.sortBy.length; i++) {
        const otherFiled = this.sortBy[i];
        const isSortDescending = this.sortDesc[i];

        result += "&";
        result += `sort=${encodeURIComponent(otherFiled)}.${
          isSortDescending ? "desc" : "asc"
        }`;
      }
    }

    return result;
  }
}

export { Page, PaginationQuery };
