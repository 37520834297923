




import Vue from "vue";
import Component from "vue-class-component";
import EventViewComponent from "../components/event/EventViewComponent.vue";

import { Dictionary } from "vue-router/types/router";

import { UiState } from "@/UiState";
import { OCRServiceProvider } from "@/services/remote/OcrService";
import { NotificationService } from "@/services/NotificationService";

@Component({
  components: {
    EventViewComponent,
  },
})
export default class EventView extends Vue {
  showView = false;

  uiState: UiState = UiState.getInstance();

  private extractEventIdFromUrlParameters(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const eventId = this.extractNumberFromUrlParameter(
        "eventId",
        this.$route.params
      );
      if (eventId === null) {
        reject(
          new Error(
            this.$t("errors.requiredParameterMissing", {
              parameterName: "eventId",
            }) as string
          )
        );
        return;
      }

      resolve(eventId);
    });
  }

  private extractNumberFromUrlParameter(
    paramName: string,
    routeParams: Dictionary<string>
  ): number | null {
    if (routeParams === null) return null;

    const valueAsString = routeParams[paramName];
    if (valueAsString === undefined || valueAsString === null) return null;

    const result = Number(valueAsString);
    if (isNaN(result)) return null;

    return result;
  }

  loadDataFromApi(eventId: number): Promise<void> {
    const ocrService = OCRServiceProvider.getInstance().getService();

    return ocrService
      .getEvent(eventId)
      .then((ocrEvent) => {
        this.uiState.ocrEvent = ocrEvent;
      })
      .then(() => ocrService.getEvents())
      .then((events) => {
        this.uiState.ocrEvents = events;
      });
  }

  loadData(): void {
    const notificationService = NotificationService.getInstance();
    notificationService
      .indicateLoading(
        this.extractEventIdFromUrlParameters().then((eventId) =>
          this.loadDataFromApi(eventId)
        ),
        this.$t("messages.loadingData") as string
      )

      .then(() => {
        this.showView = true;
      })
      .catch((err) => {
        this.showView = false;
        notificationService.notifyError(err.message);
      });
  }

  mounted(): void {
    this.loadData();
  }
}
