













import Vue from "vue";
import Component from "vue-class-component";

import EventComponent from "@/components/util/EventComponent.vue";
import TimeSeriesComponent from "./TimeSeriesComponent.vue";
import ParticipantsComponent from "./ParticipantsComponent.vue";

import { UiState } from "@/UiState";
import {
  OCREventDetails,
  OCRRun,
  OCRParticipantSearch,
  OCRParticipantStatus,
} from "@/types/OCRTypes";

@Component({
  components: {
    EventComponent,
    TimeSeriesComponent,
    ParticipantsComponent,
  },
})
export default class EventViewComponent extends Vue {
  uiState: UiState = UiState.getInstance();

  search: OCRParticipantSearch = {
    lastName: "",
    firstName: "",
    email: "",
    ticketCode: "",
    status: OCRParticipantStatus.NONE,
    eventItem: "",
    teamname: "",
  };

  onEventComponentRunClickedHandler(eventValue: {
    eventDetails: OCREventDetails;
    run: OCRRun;
    field: string;
  }): void {
    this.search.eventItem = eventValue.run.name;

    this.applyStatusFilterAndScrollDown(eventValue);
  }

  onEventComponentSummaryClickedHandler(eventValue: {
    eventDetails: OCREventDetails;
    field: string;
  }): void {
    this.applyStatusFilterAndScrollDown(eventValue);
  }

  private applyStatusFilterAndScrollDown(eventValue: { field: string }): void {
    const field = eventValue.field;
    if (field === undefined || field === null) {
      this.search.status = OCRParticipantStatus.NONE;
    } else if ("paidNotConfirmed" === field) {
      this.search.status = OCRParticipantStatus.PAID_NOT_CONFIRMED;
    } else if ("paidAndConfirmed" === field) {
      this.search.status = OCRParticipantStatus.PAID_AND_CONFIRMED;
    } else {
      this.search.status = OCRParticipantStatus.NONE;
    }

    this.$vuetify.goTo(document.body.scrollHeight);
  }
}
